import router from '..';
import { PRIVATE_ROUTES } from '../routes';
import { useAuthStore } from '@/stores/auth';
import { flattenRoutes } from '@/helpers/convert';
import { usePaymentsStore } from '@/stores/payments';

const usePaymentGuard = () => {
  const userStore = useAuthStore();
  const paymentsStore = usePaymentsStore();

  // Flatten the PRIVATE_ROUTES array to include all nested routes
  const flattenedPrivateRoutes = flattenRoutes(PRIVATE_ROUTES);

  router.beforeEach((to, from, next) => {
    const isAuthenticated = !!userStore.getToken;
    const isPrivateRoute = flattenedPrivateRoutes.some(route => route.name === to.name);
    const isPlanStatusInactive = paymentsStore.isPlanStatusInactive;

    // if the user is authenticated and the route is private, check if the user's plan status is inactive
    if (isAuthenticated && isPrivateRoute && isPlanStatusInactive) {
      // Redirect to PaymentsView if the plan status is inactive
      if (to.name !== 'PaymentsAgentView') {
        return next({ name: 'PaymentsAgentView' });
      }
    }

    next();
  });
};

export default usePaymentGuard;
