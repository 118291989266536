import AXIOS_INSTANCE from '@/api/axios.config';
import { catchError } from '@/helpers/validation';
import { defineStore, storeToRefs } from 'pinia';
import { useNewProfileStore } from '../profile';

export const usePaymentsStore = defineStore('payments', {
  state: () => ({
    plans: [],
    paymentInfo: {},
    billingProfile: {},
    transactionHistory: [],
    loadingKeys: []
  }),

  getters: {
    getPlans() {
      return this.plans;
    },
    getPaymentInfo() {
      return this.paymentInfo;
    },
    getTransactionHistory() {
      return this.transactionHistory;
    },
    getBillingProfile() {
      return this.billingProfile;
    },
    getPaymentPlanMaxListings() {
      return this.paymentInfo?.plan?.maxListings;
    },
    isBillingCardAdded() {
      // card is added if the customerId is present
      return !!this.billingProfile?.customerId;
    },
    getBillToInfo() {
      // Bill to info if the card details are present
      if (this.isBillingCardAdded) {
        return {
          firstName: this.billingProfile?.billToFirstName || null,
          lastName: this.billingProfile?.billToLastName || null,
          address: this.billingProfile?.billToAddress || null,
          city: this.billingProfile?.billToCity || null,
          state: this.billingProfile?.billToState || null,
          zip: this.billingProfile?.billToZip || null,
          country: this.billingProfile?.billToCountry || null,
          cardLastFour: this.billingProfile?.cardLastFour || null,
          verified: this.billingProfile?.verified || false
        };
      } else {
        return {};
      }
    },

    isPlanStatusActive() {
      return this.paymentInfo?.status?.toLowerCase() === 'active';
    },
    isPlanStatusInactive() {
      return this.paymentInfo?.status?.toLowerCase() === 'inactive';
    },

    isPlanSubStatusIncomplete() {
      // if true, we should redirect to PaymentsAgentView to add payment and select a plan
      return this.paymentInfo?.subStatus?.toLowerCase() === 'incomplete';
    },
    isPlanSubStatusCanceled() {
      // if true, we show an alert on PrivatLayout which is shown for all private routes
      return this.paymentInfo?.subStatus?.toLowerCase() === 'canceled';
    },
    isPlanSubStatusSuspended() {
      // if true, we show an alert on PrivatLayout which is shown for all private routes
      return this.paymentInfo?.subStatus?.toLowerCase() === 'suspended';
    },
    isPlanSubStatusExpired() {
      // if true, we should redirect to PaymentsAgentView to add payment and select a plan
      return this.paymentInfo?.subStatus?.toLowerCase() === 'expired';
    },
    isPlanSubStatusTerminated() {
      // if true, we should redirect to PaymentsAgentView to add payment and select a plan
      return this.paymentInfo?.subStatus?.toLowerCase() === 'terminated';
    },
    getCurrentPlanPrice() {
      // getting the current plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';

      if (interval === 'monthly') {
        price = this.paymentInfo?.plan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.paymentInfo?.plan?.yearlyPrice;
      }

      return price;
    },

    getLoadingKeys() {
      return this.loadingKeys;
    }
  },

  actions: {
    /**
     * Getting the plans based on the user type
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useGetPlans({ loadingKey }) {
      const newProfileStore = useNewProfileStore();
      const { getUserType } = storeToRefs(newProfileStore);

      const type = getUserType.value?.toUpperCase(); // AGENT, LENDER, BROKER, TEAM

      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/plans', {
          params: {
            type
          }
        });

        const data = res.data?.data || res.data;

        // updating the plans
        this.plans = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Getting the status of the current payment plan if exists
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useGetPaymentStatus({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/payments/status');

        const data = res.data?.data || res.data;

        // updating the payment info
        this.paymentInfo = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Refreshing the payment status with the latest info
     * @param {String} loadingKey
     * @returns {Promise}
     * */
    async useRefreshPaymentStatus({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.post('/payments/status');

        const data = res.data?.data || res.data;

        // updating the payment info
        this.paymentInfo = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Subscribing to a plan
     * @param {Object} payload - ex: { period: 'monthly', planId: 123 }
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useSubscribePlan({ payload, loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.post('/payments/subscribe', payload);

        const data = res.data?.data || res.data;

        // updating the payment info
        this.paymentInfo = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Updating the subscription plan, we update if subscriptionId exists other wise use useSubscribePlan method above
     * @param {Object} payload - ex: { period: 'monthly', planId: 123, paymentData: { type: "credit_card", billTo: { firstName: "Abdelkarim", lastName: "CHAMLAL", address: "Bentaib", city: "Bentaib", state: "Oujda", zip: "62100", country: "Morocco" } } }
     * @param {String} loadingKey
     *  @returns {Promise}
     * */
    async useUpdateSubscriptionPlan({ payload, loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.put('/payments/subscribe', payload);

        const data = res.data?.data || res.data;

        // updating the payment info
        this.paymentInfo = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Cancelling the subscription
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useCancelSubscription({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.delete('/payments/subscribe');

        const data = res.data?.data || res.data;

        // updating the payment info
        this.paymentInfo = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Getting the billing profile info like card details, bank details etc
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useGetBillingProfile({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/payments/profile');

        const data = res.data?.data || res.data;

        // updating the billing profile
        this.billingProfile = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Updating the billing profile info like card details, bank details etc
     * @param {Object} payload - ex: { "type": "credit_card", "billTo": { "firstName": "Abdelkarim", "lastName": "CHAMLAL", "address": "Bentaib", "city": "Bentaib", "state": "Oujda", "zip": "62100", "country": "Morocco" }, "cardDetails": { "cardNumber": "4012888818888", "expMonth": "06", "expYear": "2029", "cvc": "154" } }
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useUpdateBillingProfile({ payload, loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.put('/payments/profile', payload);

        const data = res.data?.data || res.data;

        // updating the billing profile
        this.billingProfile = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Getting the transaction history
     * @param {String} loadingKey
     * @returns {Promise}
     * */
    async useGetTransactionHistory({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/payments/transactions');

        const data = res.data?.data || res.data;

        // updating the transaction history
        this.transactionHistory = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    // reset the store
    useResetStore() {
      this.$reset();
    }
  }
});
