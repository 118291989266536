import { format } from 'date-fns';

// convert price to currency
export function convertPriceToCurrency({ price, currency = 'USD', fractionDigits = 0 }) {
  // if the price is not a number, throw an error
  if (isNaN(price)) {
    throw new Error(`${price} is not a number`);
  }

  // list of important currencies
  const listOfCurrencies = ['USD', 'EUR', 'GBP', 'AUD', 'BRL', 'CAD', 'CNY', 'HKD', 'IDR', 'ILS', 'INR', 'JPY', 'KRW', 'MAD'];

  // if the currency is not in the list of currencies, throw an error
  if (!listOfCurrencies.includes(currency)) {
    throw new Error(`${currency} is not in the list of currencies (${listOfCurrencies.join(', ')})`);
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    // minimum number of digits after the decimal separator
    minimumFractionDigits: fractionDigits
  });

  return formatter.format(price);
}

// getting the inverse color of a color, output is either black or white
export function getInverseColor(color) {
  let r, g, b;

  if (typeof color === 'string') {
    // Check if color is in hex format
    if (color[0] === '#') {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);

      // Check if color is in rgb format
    } else if (color.slice(0, 3) === 'rgb') {
      const colorArray = color
        .slice(4, -1)
        .split(',')
        .map(num => parseInt(num));
      r = colorArray[0];
      g = colorArray[1];
      b = colorArray[2];

      // none of the above
    } else {
      throw new Error('Invalid color format');
    }
  }

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#ffffff';
}

export function getFileSize(size) {
  // checking if the size is a number or string
  if (typeof size !== 'number' && typeof size !== 'string') {
    console.error('Size must be a number or string');
    return size;
  }

  // converting
  const sizeInBytes = parseInt(size);
  const sizeInKiloBytes = sizeInBytes / 1024;
  const sizeInMegaBytes = sizeInKiloBytes / 1024;
  const sizeInGigaBytes = sizeInMegaBytes / 1024;

  // returning the size
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInKiloBytes < 1024) {
    return `${sizeInKiloBytes.toFixed(2)} KB`;
  } else if (sizeInMegaBytes < 1024) {
    return `${sizeInMegaBytes.toFixed(2)} MB`;
  } else {
    return `${sizeInGigaBytes.toFixed(2)} GB`;
  }
}

export function getVideoDuration({ duration }) {
  if (!duration) {
    console.error('Duration is required');
    return '0:00';
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export const convertDollarToCents = dollar => {
  if (typeof dollar !== 'number') {
    console.error(`Dollar must be a number, received ${dollar}`);
    return dollar;
  }

  return dollar * 100;
};

export const convertCentsToDollar = cents => {
  if (typeof cents !== 'number') {
    console.error(`Cents must be a number, received ${cents}`);
    return cents;
  }

  return cents / 100;
};

/**
 * Returns the formatted date for an ad campaign.
 * If `options.now` is true, it returns the current date;
 * otherwise, it returns the date 7 days from now.
 *
 * @param {Boolean} options.now - Determines whether to return the current date or 7 days from now.
 */
export const getFormattedCampaginDate = ({ now = false } = {}) => {
  // either current date or 7 days from now
  const date = now ? new Date() : new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  return format(date, 'yyyy-MM-dd HH:mm:00');
};

/**
 * Flattens a nested array of routes, merging all child routes into a single array.
 * @param {Array} routes - An array of nested routes.
 * @returns {Array} - A flattened array of routes.
 */
export const flattenRoutes = routes => {
  return routes.reduce((acc, route) => {
    if (route.children) {
      // Recursively flatten child routes
      return acc.concat(route, flattenRoutes(route.children));
    }
    return acc.concat(route);
  }, []);
};
